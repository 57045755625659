.p-inputtext, .p-inputPassword{
    width: 100%;
}
.formCard{
    /* width: 35%; */
    border-radius: 20px;
    padding: 0.3rem;
    background: linear-gradient(180deg, red 10%, rgba(33, 150, 243, 0) 30%);
}
.cardBorder{
    border-radius: 20px;
}

.registerForm{
    width: 100% !important;
}
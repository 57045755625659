.bgSection {
    background: rgb(69, 187, 244);
    background: linear-gradient(90deg, rgba(69, 187, 244, 1) 0%, rgba(79, 195, 250, 1) 28%, rgba(79, 195, 250, 1) 35%);
    border-radius: 0 0 5px 5px;
}

.orderBodyCard1 {
    border-left: 5px solid #de2324;
}

.orderBodyCard2 {
    border-left: 5px solid #fbc225;
}

.orderBodyCard3 {
    border-left: 5px solid #4fc3fa;
}

/* .orderIcon{
    background-color: rgb(100, 181, 246);
} */

/* canvas{

    width:100% !important;
    height:350px !important;
  
  } */
.tbImg {
    background-image: url(https://medias.e-triangle.com/tbimg.png);
    background-position: 0px 0px;
    background-size: auto;
    width: 360px;
    height: 168px;
    background-repeat: no-repeat;
    display: inline-block;
}
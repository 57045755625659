.border-radius-5{
    border-radius: 3px !important;
}
.media{
    border-bottom: 1px solid #ccccccd7;
    margin-top: 2px;
}
.prodNameLink{
    color: #333 !important;
}
.prodNameLink:hover{
    text-decoration: underline;
}
.summaryAmount{
    color: #333;
}